import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import classNames from "classnames"
import Container from "layout/Container"
import styles from "../Elements/utils/elements.module.scss"

const Completed = ({ children, icon }) => (
  <Container isCentered>
    <center>
      <h1 className="title is-size-3 my-2 py-1">Thank you!</h1>
      <Container isCentered mobile={8} tablet={6} desktop={4}>
        <img src={icon} alt="Success!" width={128} />
      </Container>
      {children}
      <Link
        to="/"
        className={classNames(
          "button px-2 px-2-mobile mr-1 mr-0-mobile",
          styles["button_next"]
        )}
      >
        Back to Home Page
      </Link>
    </center>
  </Container>
)

export default Completed
